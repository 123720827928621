.dark_mode {
  margin-top: -20px;
  margin-left: 10px;
}

.dark_mode_label {
  width: 55px;
  height: 29px;
  position: relative;
  display: block;
  background: #00fafa;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
      inset 0px -5px 15px rgba(241, 241, 241, 0.4);
  cursor: pointer;
  transition: 0.3s;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: linear-gradient(180deg, #ffcc89, #ff7b00);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #02206d;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 53px;
  transform: translateX(-100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #7503f8;
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label .sun {
  position: absolute;

  top: 4px;
  z-index: 100;
}
.dark_mode_label .moon {
  position: absolute;

  top: 4px;
  z-index: 100;
}
.dark_mode_label .sun {
  left: 4px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label .moon {
  left: 31px;
  fill: #ffffff;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label .sun {
  fill: #ffffff;
}
.dark_mode_input:checked + .dark_mode_label .moon {
  fill: #fff;
}
